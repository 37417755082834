define("discourse/plugins/chat/discourse/components/chat-thread-list", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "ember-modifier", "truth-helpers", "discourse/components/conditional-loading-spinner", "discourse/lib/is-element-in-viewport", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/thread-list/item", "discourse/plugins/chat/discourse/modifiers/chat/track-message", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _service, _emberModifier, _truthHelpers, _conditionalLoadingSpinner, _isElementInViewport, _decorators, _discourseI18n, _item, _trackMessage, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatThreadList extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "messageBus", [_service.service]))();
    #messageBus = (() => (dt7948.i(this, "messageBus"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "chatTrackingStateManager", [_service.service]))();
    #chatTrackingStateManager = (() => (dt7948.i(this, "chatTrackingStateManager"), void 0))();
    noThreadsLabel = (() => _discourseI18n.default.t("chat.threads.none"))();
    subscribe = (() => (0, _emberModifier.modifier)((element, _ref) => {
      let [channel] = _ref;
      this.messageBus.subscribe(`/chat/${channel.id}`, this.onMessageBus, channel.channelMessageBusLastId);
      return () => {
        // TODO (joffrey) In drawer we won't have channel anymore at this point
        if (!channel) {
          return;
        }
        this.messageBus.unsubscribe(`/chat/${channel.id}`, this.onMessageBus);
      };
    }))();
    fill = (() => (0, _emberModifier.modifier)(element => {
      this.resizeObserver = new ResizeObserver(() => {
        if ((0, _isElementInViewport.default)(element)) {
          this.loadThreads();
        }
      });
      this.resizeObserver.observe(element);
      return () => {
        this.resizeObserver.disconnect();
      };
    }))();
    loadMore = (() => (0, _emberModifier.modifier)(element => {
      this.intersectionObserver = new IntersectionObserver(this.loadThreads);
      this.intersectionObserver.observe(element);
      return () => {
        this.intersectionObserver.disconnect();
      };
    }))();
    get threadsCollection() {
      return this.chatApi.threads(this.args.channel.id, this.handleLoadedThreads);
    }
    static #_5 = (() => dt7948.n(this.prototype, "threadsCollection", [_tracking.cached]))();
    loadThreads() {
      this.threadsCollection.load({
        limit: 10
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "loadThreads", [_decorators.bind]))();
    get threadsManager() {
      return this.args.channel.threadsManager;
    }
    // NOTE: This replicates sort logic from the server. We need this because
    // the thread unread count + last reply date + time update when new messages
    // are sent to the thread, and we want the list to react in realtime to this.
    get sortedThreads() {
      return this.threadsManager.threads.filter(thread => !thread.originalMessage.deletedAt && thread.originalMessage?.id !== thread.lastMessageId).sort((threadA, threadB) => {
        // if both threads have watched unread count, then show latest first
        if (threadA.tracking.watchedThreadsUnreadCount && threadB.tracking.watchedThreadsUnreadCount) {
          if (threadA.preview.lastReplyCreatedAt > threadB.preview.lastReplyCreatedAt) {
            return -1;
          } else {
            return 1;
          }
        }
        // sort threads by watched unread count
        if (threadA.tracking.watchedThreadsUnreadCount) {
          return -1;
        }
        if (threadB.tracking.watchedThreadsUnreadCount) {
          return 1;
        }
        // If both are unread we just want to sort by last reply date + time descending.
        if (threadA.tracking.unreadCount && threadB.tracking.unreadCount) {
          if (threadA.preview.lastReplyCreatedAt > threadB.preview.lastReplyCreatedAt) {
            return -1;
          } else {
            return 1;
          }
        }
        // If one is unread and the other is not, we want to sort the unread one first.
        if (threadA.tracking.unreadCount) {
          return -1;
        }
        if (threadB.tracking.unreadCount) {
          return 1;
        }
        // If both are read, we want to sort by last reply date + time descending.
        if (threadA.preview.lastReplyCreatedAt > threadB.preview.lastReplyCreatedAt) {
          return -1;
        } else {
          return 1;
        }
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "sortedThreads", [_tracking.cached]))();
    get lastThread() {
      return this.sortedThreads[this.sortedThreads.length - 1];
    }
    get shouldRender() {
      return !!this.args.channel;
    }
    onMessageBus(busData) {
      switch (busData.type) {
        case "delete":
          this.handleDeleteMessage(busData);
          break;
        case "restore":
          this.handleRestoreMessage(busData);
          break;
      }
    }
    static #_8 = (() => dt7948.n(this.prototype, "onMessageBus", [_decorators.bind]))();
    handleDeleteMessage(data) {
      const deletedOriginalMessageThread = this.threadsManager.threads.findBy("originalMessage.id", data.deleted_id);
      if (!deletedOriginalMessageThread) {
        return;
      }
      deletedOriginalMessageThread.originalMessage.deletedAt = new Date();
    }
    handleRestoreMessage(data) {
      const restoredOriginalMessageThread = this.threadsManager.threads.findBy("originalMessage.id", data.chat_message.id);
      if (!restoredOriginalMessageThread) {
        return;
      }
      restoredOriginalMessageThread.originalMessage.deletedAt = null;
    }
    handleLoadedThreads(result) {
      return result.threads.map(thread => {
        const threadModel = this.threadsManager.add(this.args.channel, thread, {
          replace: true
        });
        this.chatTrackingStateManager.setupChannelThreadState(this.args.channel, result.tracking);
        return threadModel;
      });
    }
    static #_9 = (() => dt7948.n(this.prototype, "handleLoadedThreads", [_decorators.bind]))();
    static #_10 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldRender}}
          <div class="chat-thread-list" {{this.subscribe @channel}}>
            <div class="chat-thread-list__items" {{this.fill}}>
    
              {{#each this.sortedThreads key="id" as |thread|}}
                <ChatThreadListItem
                  @thread={{thread}}
                  {{(if
                    (eq thread this.lastThread)
                    (modifier ChatTrackMessage this.load)
                  )}}
                />
              {{else}}
                {{#if this.threadsCollection.fetchedOnce}}
                  <div class="chat-thread-list__no-threads">
                    {{this.noThreadsLabel}}
                  </div>
                {{/if}}
              {{/each}}
    
              <ConditionalLoadingSpinner
                @condition={{this.threadsCollection.loading}}
              />
    
              <div {{this.loadMore}}>
                <br />
              </div>
            </div>
          </div>
        {{/if}}
      
    */
    {
      "id": "puIZL12K",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"      \"],[11,0],[24,0,\"chat-thread-list\"],[4,[30,0,[\"subscribe\"]],[[30,1]],null],[12],[1,\"\\n        \"],[11,0],[24,0,\"chat-thread-list__items\"],[4,[30,0,[\"fill\"]],null,null],[12],[1,\"\\n\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"sortedThreads\"]]],null]],null],\"id\",[[[1,\"            \"],[8,[32,0],[[4,[52,[28,[32,1],[[30,2],[30,0,[\"lastThread\"]]],null],[50,[32,2],2,[[30,0,[\"load\"]]],null]],null,null]],[[\"@thread\"],[[30,2]]],null],[1,\"\\n\"]],[2]],[[[41,[30,0,[\"threadsCollection\",\"fetchedOnce\"]],[[[1,\"              \"],[10,0],[14,0,\"chat-thread-list__no-threads\"],[12],[1,\"\\n                \"],[1,[30,0,[\"noThreadsLabel\"]]],[1,\"\\n              \"],[13],[1,\"\\n\"]],[]],null]],[]]],[1,\"\\n          \"],[8,[32,3],null,[[\"@condition\"],[[30,0,[\"threadsCollection\",\"loading\"]]]],null],[1,\"\\n\\n          \"],[11,0],[4,[30,0,[\"loadMore\"]],null,null],[12],[1,\"\\n            \"],[10,\"br\"],[12],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@channel\",\"thread\"],false,[\"if\",\"each\",\"-track-array\",\"modifier\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-thread-list.js",
      "scope": () => [_item.default, _truthHelpers.eq, _trackMessage.default, _conditionalLoadingSpinner.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatThreadList;
});